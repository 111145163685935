<div class="!p-4 bg-white rounded-lg border border-palette-gray-200 shadow-sm">
    <p class="text-palette-gray-900 text-base font-semibold">{{ 'FLIGHT.MY_BOOKING.GUEST_INFORMATION' | translate }}</p>
    <!-- TABLE PASSENGER -->
    <div class="relative overflow-x-auto !mt-2 border !rounded-lg border-palette-gray-200 !font-medium">
        <table class="min-w-full">
            <thead
                class="text-sm text-palette-gray-900 font-medium !rounded-t-lg bg-palette-gray-100">
                <tr class="border-b">
                    <td class="rounded-tl-lg sticky left-0 z-10 bg-palette-gray-100 whitespace-nowrap">{{ 'FLIGHT.PAYMENT.PASSENGER' | translate }}</td>
                    <td class="whitespace-nowrap">{{ 'MY_BOOKING.INFORMATION' | translate }}</td>
                    <td class="whitespace-nowrap">{{ 'MY_BOOKING.ADD_ONS' | translate }}</td>
                </tr>
            </thead>
            <tbody>
                <ng-container
                    *ngFor="let passenger of flightDetail?.passenger_information; let last = last">
                    <tr [class.border-b]="!last">
                        <td class="!py-2 sticky left-0 z-10 bg-white whitespace-nowrap">
                            <div class="flex flex-row items-center !gap-1 text-sm text-palette-gray-900 font-medium">
                                <p class="font-medium">{{ ('COMMON.' + (passenger.title | uppercase)) | translate }} 
                                    <span class="uppercase">{{ passenger.middle_and_first_name }} {{ passenger.last_name }}</span>
                                </p>
                                <div 
                                    *ngIf="type === 'DEPART' ? passenger?.depart_frequent_flyer_program : passenger?.return_frequent_flyer_program"
                                    (mouseenter)="onMouseEnter(
                                        $event,
                                        type === 'DEPART' ? passenger?.depart_frequent_flyer_program : passenger?.return_frequent_flyer_program,
                                        type === 'DEPART' ? passenger?.depart_frequent_flyer_number : passenger?.return_frequent_flyer_number
                                    )"
                                    (mouseleave)="onMouseLeave()"
                                    class="hover:cursor-pointer relative"
                                >
                                    <div
                                        class="!w-5 !h-5 flex items-center justify-center text-white
                                            rounded-full bg-palette-amber-500 font-inter text-xs"
                                    >
                                        M
                                    </div>
                                </div>
                            </div>
                            <p *ngIf="(type === 'DEPART' && passenger?.depart_ticket_number) || (type === 'RETURN' && passenger?.return_ticket_number)"
                                class="text-palette-gray-500 text-xs mt-[2px]">
                                {{ 'MY_BOOKING.TICKET_NO' | translate }}: {{ type === 'DEPART' ? passenger.depart_ticket_number : passenger.return_ticket_number }}
                            </p>
                            <p class="{{passenger?.passenger_type === 'Infant' || passenger?.passenger_type === 'INF' ? 'text-palette-red-600' : 'text-palette-gray-500'}} 
                                text-xs mt-[2px]">
                                {{ 'FLIGHT.PAYMENT.' + passenger.passenger_type.toUpperCase() | translate }} {{ passenger?.passenger_index_type }}
                            </p>
                        </td>
                        <td class="!py-2 w-fit">
                            <p class="text-palette-gray-500 text-sm whitespace-nowrap">
                                {{ 'MY_BOOKING.DAY_OF_BIRTH' | translate }}: <span class="text-palette-gray-900">{{ passenger.date_of_birth | dateFormat }}</span>
                            </p>
                            <p *ngIf="passenger.passport_identity" class="text-palette-gray-500 text-sm mt-[2px] whitespace-nowrap">
                                {{ (hasVJCodeFlights ? 'FLIGHT.PASSENGERS.CCCD_NUMBER' : 'FLIGHT.PASSENGERS.PASSPORT_NUMBER') | translate }}: 
                                <span class="text-palette-gray-900">{{ passenger.passport_identity }}</span>
                            </p>
                            <p *ngIf="passenger.phone" class="text-palette-gray-500 text-sm mt-[2px] whitespace-nowrap">
                                {{ 'MY_BOOKING.PHONE_NUMBER' | translate }}: <span class="text-palette-gray-900">{{ passenger.phone }}</span>
                            </p>
                        </td>
                        <td class="!py-2 w-fit">
                            <ng-container *ngIf="passenger?.[targetAddOns]?.length; else noData">
                                <div *ngFor="let addOn of passenger[targetAddOns]; let lastItem = last" class="w-fit {{!lastItem ? '!mb-3' : ''}}">
                                    <div *ngIf="(type === 'DEPART' && departSegments.length > 1) || (type === 'RETURN' && returnSegments?.length > 1)" class="rounded border border-palette-gray-600 !px-2 !py-[2px] text-palette-gray-600 text-xs font-medium w-fit">
                                        {{ addOn.origin_iata_code }} <i class="sctr-icon-arrow-right text-xs font-semibold"></i> {{ addOn.destination_iata_code }}
                                    </div>
                                    <p *ngIf="addOn?.seat" class="text-palette-gray-500 text-sm whitespace-nowrap !mt-1">
                                        {{ 'FLIGHT.PAYMENT.SEAT' | translate }}: <span class="text-palette-gray-900">{{ addOn.seat }}</span>
                                    </p>
                                    <p *ngIf="addOn?.extra_baggage" class="text-palette-gray-500 text-sm !mt-[2px] whitespace-nowrap">
                                        {{ 'FLIGHT.PAYMENT.EXTRA_BAGGAGE_1' | translate }}: 
                                        <span class="text-palette-gray-900">
                                            <ng-container *ngIf="addOn.extra_baggage?.description && (addOn.extra_baggage?.description?.toUpperCase()?.includes('OVERSIZE') ||
                                                addOn.extra_baggage?.description?.toUpperCase()?.includes('HLQK')); else normalWeight">
                                                {{ 'COMMON.OVERSIZE' | translate}} {{addOn.extra_baggage.weight}}kg
                                            </ng-container>
                                            <ng-template #normalWeight>
                                                {{addOn.extra_baggage?.weight}}kg
                                            </ng-template>
                                        </span>
                                    </p>
                                    <div *ngIf="addOn?.meals" class="text-palette-gray-500 text-sm !mt-[2px] flex !gap-1">
                                        <span class="whitespace-nowrap">{{ 'FLIGHT.PAYMENT.MEAL' | translate }}: </span>
                                        <div class="flex flex-col !gap-1 w-fit">
                                            <span *ngFor="let meal of addOn.meals; let last = last" class="text-palette-gray-900 whitespace-nowrap">
                                                {{ meal.meal_name }} x {{ meal.quantity }}<span *ngIf="!last">, </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noData>
                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'popup-recommend-sign-in',
  templateUrl: './popup-recommend-sign-in.component.html',
  styleUrls: ['./popup-recommend-sign-in.component.scss'],
})

export class PopupRecommendSignIn {
  @Output() onClose = new EventEmitter<any>();

  constructor(
    private router: Router,
  ){}
  isShowPopup: boolean = false;
  pipe = new DatePipe('en-US');

  ngOnInit(): void {
    this.isShowPopup = true;
    document.body.style.overflow = 'hidden';
  }

  closePopup(): void {
    this.onClose.emit(false);
    this.isShowPopup = false;
    document.body.style.overflow = 'auto';
  }
  
  stopPropagationEvent(event: Event): void {
    event.stopPropagation();
  }

  onSignup() {
    window.location.href = environment.SOCIAL_APP_URL + '/sign-up';
  }
}
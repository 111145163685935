import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { IVoucher } from '@modules/booking/flight/models/common.model';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { ApiService } from 'src/app/services/api-service/api-service.service';

@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.scss'],
    providers: [MessageService],
})
export class VoucherComponent {
    @Input() voucherConfirmed: any;
    @Input() indexVoucherConfirmed: number | null;
    @Input() totalPrice: number;
    @Output() confirmVoucherEmit = new EventEmitter<any>();
    @Input() flightInfo: any;
    @Input() classOption: any;

    isShowVoucherPopup: boolean = false;
    isGettingVoucher: boolean = false;
    totalVoucher: number = 0;
    indexSelectedVoucher: number | null = null;
    backupIndexSelectedVoucher: number | null = null;;
    public_voucher_valid: IVoucher[] = [];
    public_voucher_invalid: IVoucher[] = [];
    private_voucher: IVoucher | null = null;
    backup_private_voucher: IVoucher | null = null;
    voucher_confirmed: any = [];
    pageNum: number = 0;
    pageSize: number = 10;
    filterVoucher: string = "is_public==true,modules@=FLIGHT,display_setting@=ALL_PAGE";
    isLogin: boolean | null = false;
    scrollEventListenerAdded: boolean = false;
    showPromoCodeCard: boolean = false;
    promoCode: string;
    invalidPromoCode: boolean = false;
    indexPublicVoucherApplyByCode: number | null = null;
    isFindingVoucher: boolean = false;
    baseUrl = environment.BASE_PATH;
    isConfirmVoucher: boolean = false;
    isLoading: boolean = false;
    isInitial: boolean = false;
    onDestroy$: Subject<void> = new Subject();

    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private messageService: MessageService,
        private translateService: TranslateService,
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isInitial && changes['voucherConfirmed'] && changes['voucherConfirmed'].currentValue) {
            this.indexSelectedVoucher = this.backupIndexSelectedVoucher = this.indexVoucherConfirmed;
            this.voucher_confirmed = this.voucherConfirmed;
        }
      
    }

    ngOnInit() {
        this.userService
            .getLoginStatus()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data !== null) {
                    this.isLogin = data;
                }
            });

        if (this.voucherConfirmed) {
            this.indexSelectedVoucher = this.backupIndexSelectedVoucher = this.indexVoucherConfirmed;
            this.voucher_confirmed = this.voucherConfirmed;

            if (this.indexVoucherConfirmed === -1) {
                this.private_voucher = this.backup_private_voucher = this.voucherConfirmed[0];
            }
        }
        if (!this.totalPrice) this.totalPrice = 0;
        this.isInitial = true;
    }

    ngOnDestroy() {
        // Remove event listener lazy load when scroll
        const dialogContentElement = document.querySelector('.p-dialog-content');
        if (dialogContentElement && this.scrollEventListenerAdded) {
            dialogContentElement.removeEventListener('scroll', this.handleScrollPopup);
        }
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    handleClickVoucherPopup() {
        this.isShowVoucherPopup = true;
        this.isLoading = true;
        this.pageNum = 0;
        this.public_voucher_valid = [];
        this.public_voucher_invalid = [];
        this.getPublicVouchers(this.pageNum, this.pageSize, this.filterVoucher);
    }

    getPublicVouchers(pageNum: number, pageSize: number, filters: string) {
        this.isGettingVoucher = true;

        const dataParams: any[] = []

        this.flightInfo.flight_info.itineraries.map((flightInfo:any,  index: number)=> {
            let data = {
                airline: flightInfo?.segments[0]?.operating_code || '',
                origin:  flightInfo?.segments[0]?.departure.iata_code || '',
                destination: flightInfo?.segments[flightInfo.segments.length - 1]?.arrival.iata_code || '',
                class_name: this.classOption || '',
                total: this.flightInfo?.price_details[index]?.final_price || 0,
                extraData: [
                  
                ]
            }
            dataParams.push(data)
        })
        // keep the log for testing.
        console.warn("dataParams", dataParams);

        const url = `${environment.BASE_PATH}/voucher-hub/vouchers/module-management?tab=ONGOING&pageNum=${pageNum}&pageSize=${pageSize}&filters=${encodeURIComponent(filters)}&base64_order_payload=${btoa(JSON.stringify(dataParams))}`;
        this.apiService.get(url)
            .subscribe({
                next: (response) => {
                    this.isGettingVoucher = false;
                    this.isLoading = false;
                    if (response && response.data && Array.isArray(response.data.data)) {
                        this.totalVoucher = response.data.totalElement;
                        // Add voucher selected to first list
                        if (!this.public_voucher_valid.length && this.voucher_confirmed.length && this.indexSelectedVoucher !== -1) {
                            this.public_voucher_valid.push(this.voucher_confirmed[0]);
                        }

                        response.data.data.forEach((voucher: any) => {
                            if (voucher.status === 'Ongoing' && voucher.id !== this.voucher_confirmed?.[0]?.id) {
                                const formattedVoucher = {
                                    id: voucher.id,
                                    code: voucher.code,
                                    voucher_code: voucher.voucher_code,
                                    avatar_id: voucher.avatar_id,
                                    name: voucher.name,
                                    discount_percent: voucher.voucher_discount_range[0].discount_percent,
                                    discount_amount: voucher.voucher_discount_range[0].discount_amount,
                                    voucher_discount_range: this.findBestDiscount(voucher.voucher_discount_range, this.totalPrice),
                                    min_order_price: voucher.voucher_discount_range[0].min_order_price,
                                    end_date: voucher.end_date,
                                    valid: this.totalPrice >= voucher.voucher_discount_range[0].min_order_price,
                                    checked: false,
                                }
                                if (this.totalPrice >= voucher.voucher_discount_range[0].min_order_price) {
                                    this.public_voucher_valid.push(formattedVoucher);
                                } else {
                                    this.public_voucher_invalid.push(formattedVoucher);
                                }
                            }
                        });
                    }
                },
                error: (error) => {
                    this.isGettingVoucher = false;
                    this.isLoading = false;
                }
            });
    }

    // Handle lazy load voucher when scroll
    handleShowVoucherPopup() {
        if (!this.scrollEventListenerAdded) {
            const element = document.querySelector('.p-dialog-content');
            if (element) {
                element.addEventListener('scroll', this.handleScrollPopup.bind(this));
                this.scrollEventListenerAdded = true;
            }
        }
    }

    handleScrollPopup(event: Event) {
        const dialogContentElement = event.target as HTMLElement;
        if (!dialogContentElement || this.isGettingVoucher || this.pageNum * this.pageSize >= this.totalVoucher) {
            return;
        }
        const { scrollTop, scrollHeight, clientHeight } = dialogContentElement;

        if (scrollHeight - scrollTop - clientHeight <= 400) {
            this.pageNum++;
            this.getPublicVouchers(this.pageNum, this.pageSize, this.filterVoucher);
        }
    }

    handleCancelPromoVoucher() {
        this.isShowVoucherPopup = false;
        this.isLoading = false;
        this.indexSelectedVoucher = this.backupIndexSelectedVoucher;

        this.handleCancelPromoCodeCard();
        if (this.backupIndexSelectedVoucher !== -1) {
            this.private_voucher = null;
            this.indexPublicVoucherApplyByCode = null;
        } else {
            this.private_voucher = this.backup_private_voucher;
        }
    }

    handleClickAddPromoCode() {
        this.showPromoCodeCard = true;
    }

    handleCancelPromoCodeCard() {
        this.promoCode = '';
        this.invalidPromoCode = false;
        this.showPromoCodeCard = false;
    }

    handleClickSignInOrSignUp(): void {
        window.location.href = environment.SOCIAL_APP_URL + '/login?r=' + encodeURIComponent(window.location.href);
    }

    handleChangeInput() {
        this.invalidPromoCode = false;
    }

    applyPromoCode() {
        this.isFindingVoucher = true;
        if (this.private_voucher?.voucher_code !== this.promoCode.toUpperCase()) {
            this.findVoucherByCode(this.promoCode);
        } else {
            this.promoCode = '';
            this.isFindingVoucher = false;
        }
    }

    findVoucherByCode(code: string) {
        const dataParams: any[] = []

        this.flightInfo.flight_info.itineraries.map((flightInfo:any,  index: number)=> {
            let data = {
                airline: flightInfo?.segments[0]?.operating_code || '',
                origin:  flightInfo?.segments[0]?.departure.iata_code || '',
                destination: flightInfo?.segments[flightInfo.segments.length - 1].arrival.iata_code || '',
                class_name: this.classOption || '',
                total: this.flightInfo?.price_details[index]?.final_price || 0,
                extraData: [
                  
                ]
            }
            dataParams.push(data)
        })
        // keep the log for testing.
        console.warn("dataParams", dataParams);
        const url = `${environment.BASE_PATH}/voucher-hub/vouchers/voucher/code/${code.toUpperCase()}?module=FLIGHT&base64_order_payload=${btoa(JSON.stringify(dataParams))}`;

        this.apiService.get(url)
            .subscribe({
                next: response => {
                    if (response && response.success) {
                        const voucher = {
                            id: response.data.id,
                            code: response.data.code,
                            voucher_code: response.data.voucher_code,
                            voucher_value: response.data.voucher_value,
                            avatar_id: response.data.avatar_id,
                            name: response.data.name,
                            discount_percent: response.data.voucher_discount_range[0].discount_percent,
                            discount_amount: response.data.voucher_discount_range[0].discount_amount,
                            voucher_discount_range: this.findBestDiscount(response.data.voucher_discount_range, this.totalPrice),
                            min_order_price: response.data.voucher_discount_range[0].min_order_price,
                            end_date: response.data.end_date,
                            valid: this.totalPrice >= response.data.voucher_discount_range[0].min_order_price && response.data.status === 'Ongoing',
                            checked: false,
                        }

                        this.private_voucher = voucher;
                        // Check if existing in public voucher
                        this.public_voucher_valid.forEach((voucher, index) => {
                            if (voucher.voucher_code === this.promoCode.toUpperCase()) {
                                this.indexPublicVoucherApplyByCode = index;
                            }
                        })
                        // Set index selected voucher
                        if (voucher.valid) this.indexSelectedVoucher = -1;
                        // Reset input private voucher
                        this.promoCode = '';
                    } else {
                        this.invalidPromoCode = true;
                    }
                    this.isFindingVoucher = false;
                },
                error: error => {
                    if (error?.error?.message) {
                        this.showError(error?.error?.message);
                    } else {
                        const translatedText = this.translateService.instant(
                            'COMMON.API_ERROR_MESSAGE'
                        );
                        this.showError(translatedText);
                    }
                    this.isFindingVoucher = false;
                }
            });
    }

    // Return best discount range follow total price
    findBestDiscount(discounts: any[], orderAmount: number): any | null {
        if (!orderAmount) orderAmount = 0;
        if (!discounts || !discounts.length) return {
            discount_amount: null,
            discount_percent: null,
            min_order_price: null
        };
        return (
            discounts
                .filter(discount => orderAmount >= discount.min_order_price)
                .sort((a, b) => b.min_order_price - a.min_order_price)
                .find(() => true) || discounts[0]
        );
    }

    // Handle when click voucher
    handleSelectVoucher(voucher: any, index: number) {
        if (voucher.valid) {
            this.indexSelectedVoucher === index ? this.indexSelectedVoucher = null : this.indexSelectedVoucher = index;
        }
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/icons/voucher-payment/ticket.svg';
        event.target.classList.add('replace-img')
    }

    handleConfirmPromoVoucher() {
        this.isShowVoucherPopup = false;
        this.isLoading = false;
        if (this.indexSelectedVoucher !== null && this.indexSelectedVoucher >= -1) {
            if (this.indexSelectedVoucher === -1) {
                this.confirmVoucherEmit.emit({
                    indexSelectedVoucher: -1,
                    voucher: [this.private_voucher]
                });
                this.voucher_confirmed = [this.private_voucher];
                this.backup_private_voucher = this.private_voucher;
            } else {
                this.confirmVoucherEmit.emit({
                    indexSelectedVoucher: 0,
                    voucher: [this.public_voucher_valid[this.indexSelectedVoucher]]
                })
                this.voucher_confirmed = [this.public_voucher_valid[this.indexSelectedVoucher]];
                // Move selected voucher to first public list voucher
                this.indexSelectedVoucher = 0;
            }
        } else {
            this.confirmVoucherEmit.emit({
                indexSelectedVoucher: null,
                voucher: [],
            })
            this.voucher_confirmed = [];
            this.private_voucher = null;
        }
        this.backupIndexSelectedVoucher = this.indexSelectedVoucher;
    }


    showError(detail: string) {
        this.messageService.add({
            key: 'FILE_ERROR',
            severity: 'error',
            detail: detail,
        });
    }


}

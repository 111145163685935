import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  showDownloadAppDialog = false;
  private readonly OPEN_APP_STORE_TIMEOUT = 2000;

  get showDownloadAppPopup() {
    return this.isMobile() && !this.showDownloadAppDialog;
  }

  private get userAgent(): string {
    return navigator.userAgent;
  }

  public isMobile(): boolean {
    return (
      (this.isAndroid() || this.isIOS()) &&
      (window.matchMedia('(hover: none) and (pointer: coarse)').matches || navigator.maxTouchPoints > 1)
    );
  }

  public isAndroid(): boolean {
    return !!this.userAgent.match(/Android/i);
  }

  public isIOS(): boolean {
    const isStandardIOS = /iPhone|iPad|iPod/i.test(this.userAgent);
    const isModernIPad = /Macintosh/i.test(this.userAgent) && 'ontouchend' in document;

    return isStandardIOS || isModernIPad;
  }

  //Try to open app by deep link before showing download app dialog.
  public tryOpenApp(deepLink: string): void {
    //Temporarily keep the log for testing.
    console.warn('🚀 ~ deepLink:', deepLink);
    window.location.href = deepLink;
    setTimeout(() => {
      this.showDownloadAppDialog = true;
    }, this.OPEN_APP_STORE_TIMEOUT);
  }

  public openAppStore(): void {
    let storeUrl = '';

    if (this.isAndroid()) {
      storeUrl = environment.ANDROID_DOWNLOAD;
    } else if (this.isIOS()) {
      storeUrl = environment.IOS_DOWNLOAD;
    }

    if (storeUrl) {
      window.open(storeUrl, '_blank')?.focus();
    }
  }
}
